.App {
    /* font-family: "EF_Diary"; */
    /* font-family: "Tenada"; */
    font-family : "CookieRun_Regular";
}

@font-face {
    /* font-family: EF_Diary;
    src: url(/src/assets/fonts/EF_Diary.ttf); */
    font-family: CookieRun_Regular;
    src: url(/src/assets/fonts/CookieRun_Regular.ttf);
    
}

